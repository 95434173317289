import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spells-acid-arrow-to-burning-hands"></a><h2>Spells</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Acid Arrow - Burning Hands</span>
    </h2>
    <h6><a id="acid-arrow">Acid Arrow</a></h6>
    <p className="initial"><i>Conjuration (Creation) [Acid]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Effect</b>: One arrow of
acid</span> <span className="stat-block"><b>Duration</b>: 1 round + 1
round per
three levels</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>A magical arrow of acid springs from your hand and speeds to
its target. You must succeed on a ranged touch attack to hit your
target. The arrow deals 2d4 points of acid damage with no splash
damage. For every three caster levels (to a maximum of 18th), the acid,
unless somehow neutralized, lasts for another round, dealing another
2d4 points of damage in that round.</p>
    <p><i>Material Component</i>: Powdered rhubarb leaf and an
adder&rsquo;s stomach.</p>
    <p><i>Focus</i>: A dart.</p>
    <h6><a id="acid-fog">Acid Fog</a></h6>
    <p className="initial"><i>Conjuration (Creation) [Acid]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 6, Water 7</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Fog spreads
in 20-ft.
radius, 20 ft. high</span> <span className="stat-block"><b>Duration</b>: 1
round/level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Acid fog creates a billowing mass of misty vapors similar to
that produced by a solid fog spell. In addition to slowing creatures
down and obscuring sight, this spell&rsquo;s vapors are highly acidic.
Each
round on your turn, starting when you cast the spell, the fog deals 2d6
points of acid damage to each creature and object within it.</p>
    <p><i>Arcane Material Component</i>: A pinch of dried, powdered
peas combined with powdered animal hoof.</p>
    <h6><a id="acid-splash">Acid Splash</a></h6>
    <p className="initial"><i>Conjuration (Creation) [Acid]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: One missile of
acid</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You fire a small orb of acid at the target. You must succeed
on a ranged touch attack to hit your target. The orb deals 1d3 points
of acid damage.</p>
    <h6><a id="aid">Aid</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2, Good 2, Luck 2</span>
    <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Living creature touched</span> <span className="stat-block"><b>Duration</b>:
1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes (harmless)</span>
    <p>Aid grants the target a +1 morale bonus on attack rolls and
saves against fear effects, plus temporary hit points equal to 1d8 +
caster level (to a maximum of 1d8+10 temporary hit points at caster
level 10th).</p>
    <h6><a id="air-walk">Air Walk</a></h6>
    <p className="initial"><i>Transmutation [Air]</i></p>
    <span className="stat-block"><b>Level</b>: Air 4, Clr 4, Drd 4</span>
    <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature (Gargantuan or
smaller) touched</span> <span className="stat-block"><b>Duration</b>: 10
min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes (harmless)</span>
    <p>The subject can tread on air as if walking on solid ground.
Moving upward is similar to walking up a hill. The maximum upward or
downward angle possible is 45 degrees, at a rate equal to one-half the
air walker&rsquo;s normal speed.</p>
    <p>A strong wind (21+ mph) can push the subject along or hold it
back. At the end of its turn each round, the wind blows the air walker
5 feet for each 5 miles per hour of wind speed. The creature may be
subject to additional penalties in exceptionally strong or turbulent
winds, such as loss of control over movement or physical damage from
being buffeted about.</p>
    <p>Should the spell duration expire while the subject is still
aloft, the magic fails slowly. The subject floats downward 60 feet per
round for 1d6 rounds. If it reaches the ground in that amount of time,
it lands safely. If not, it falls the rest of the distance, taking 1d6
points of damage per 10 feet of fall. Since dispelling a spell
effectively ends it, the subject also descends in this way if the air
walk spell is dispelled, but not if it is negated by an antimagic field.</p>
    <p>You can cast air walk on a specially trained mount so it can
be ridden through the air. You can train a mount to move with the aid
of air walk (counts as a trick; see <a href="skillsAll.html#handle-animal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Handle
Animal</a> skill) with one week
of work and a DC 25 Handle Animal check.</p>
    <h6><a id="alarm">Alarm</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Rgr 1, Sor/Wiz 1</span>
    <span className="stat-block"><b>Components</b>: V, S, F/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Area</b>: 20-ft.-radius
emanation
centered on a point in space</span> <span className="stat-block"><b>Duration</b>:
2 hours/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Alarm sounds a mental or audible alarm each time a creature of
Tiny or larger size enters the warded area or touches it. A creature
that speaks the password (determined by you at the time of casting)
does not set off the alarm. You decide at the time of casting whether
the alarm will be mental or audible.</p>
    <p><i>Mental Alarm</i>: A mental alarm alerts you (and only you)
so long as you remain within 1 mile of the warded area. You note a
single mental &ldquo;ping&rdquo; that awakens you from normal sleep but
does not
otherwise disturb concentration. A silence spell has no effect on a
mental alarm.</p>
    <p><i>Audible Alarm</i>: An audible alarm produces the sound of a
hand bell, and anyone within 60 feet of the warded area can hear it
clearly. Reduce the distance by 10 feet for each interposing closed
door and by 20 feet for each substantial interposing wall.</p>
    <p>In quiet conditions, the ringing can be heard faintly as far
as 180 feet away. The sound lasts for 1 round. Creatures within a
silence spell cannot hear the ringing.</p>
    <p>Ethereal or astral creatures do not trigger the alarm.</p>
    <p>Alarm can be made permanent with a permanency spell.</p>
    <p><i>Arcane Focus</i>: A tiny bell and a piece of very fine
silver wire</p>
    <h6><a id="align-weapon">Align Weapon</a></h6>
    <p className="initial"><i>Transmutation [see text]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Weapon touched or fifty
projectiles (all of which must be in contact with each other at the
time of casting)</span> <span className="stat-block"><b>Duration</b>: 1
min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(harmless, object)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless,
object)</span>
    <p>Align weapon makes a weapon good, evil, lawful, or chaotic, as
you choose. A weapon that is aligned can bypass the <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a> of
certain creatures. This spell has no effect on a weapon that already
has an alignment.</p>
    <p>You can&rsquo;t cast this spell on a natural weapon, such as
an
unarmed strike.</p>
    <p>When you make a weapon good, evil, lawful, or chaotic, align
weapon is a good, evil, lawful, or chaotic spell, respectively.</p>
    <h6><a id="alter-self">Alter Self</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
10 min./level (D)</span>
    <p>You assume the form of a creature of the same type as your
normal form. The new form must be within one size category of your
normal size. The maximum HD of an assumed form is equal to your caster
level, to a maximum of 5 HD at 5th level. You can change into a member
of your own kind or even into yourself.</p>
    <p>You retain your own ability scores. Your class and level, hit
points, alignment, base attack bonus, and base save bonuses all remain
the same. You retain all supernatural and spell-like special attacks
and qualities of your normal form, except for those requiring a body
part that the new form does not have (such as a mouth for a breath
weapon or eyes for a gaze attack).</p>
    <p>You keep all extraordinary special attacks and qualities
derived from class levels, but you lose any from your normal form that
are not derived from class levels.</p>
    <p>If the new form is capable of speech, you can communicate
normally. You retain any spellcasting ability you had in your original
form, but the new form must be able to speak intelligibly (that is,
speak a language) to use verbal components and must have limbs capable
of fine manipulation to use somatic or material components.</p>
    <p>You acquire the physical qualities of the new form while
retaining your own mind. Physical qualities include natural size,
mundane movement capabilities (such as burrowing, climbing, walking,
swimming, and flight with wings, to a maximum speed of 120 feet for
flying or 60 feet for nonflying movement), natural armor bonus, natural
weapons (such as claws, bite, and so on), racial skill bonuses, racial
bonus feats, and any gross physical qualities (presence or absence of
wings, number of extremities, and so forth). A body with extra limbs
does not allow you to make more attacks (or more advantageous
two-weapon attacks) than normal.</p>
    <p>You do not gain any extraordinary special attacks or special
qualities not noted above under physical qualities, such as darkvision,
low-light vision, blindsense, blindsight, fast healing, regeneration,
scent, and so forth.</p>
    <p>You do not gain any supernatural special attacks, special
qualities, or spell-like abilities of the new form. Your creature type
and subtype (if any) remain the same regardless of your new form. You
cannot take the form of any creature with a template, even if that
template doesn&rsquo;t change the creature type or subtype.</p>
    <p>You can freely designate the new form&rsquo;s minor physical
qualities (such as hair color, hair texture, and skin color) within the
normal ranges for a creature of that kind. The new form&rsquo;s
significant
physical qualities (such as height, weight, and gender) are also under
your control, but they must fall within the norms for the new
form&rsquo;s
kind. You are effectively disguised as an average member of the new
form&rsquo;s race. If you use this spell to create a disguise, you get
a +10
bonus on your <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a> check.</p>
    <p>When the change occurs, your equipment, if any, either remains
worn or held by the new form (if it is capable of wearing or holding
the item), or melds into the new form and becomes nonfunctional. When
you revert to your true form, any objects previously melded into the
new form reappear in the same location on your body they previously
occupied and are once again functional. Any new items you wore in the
assumed form and can&rsquo;t wear in your normal form fall off and land
at
your feet; any that you could wear in either form or carry in a body
part common to both forms at the time of reversion are still held in
the same way. Any part of the body or piece of equipment that is
separated from the whole reverts to its true form.</p>
    <h6><a id="analyze-dweomer">Analyze Dweomer</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One object or
creature
per caster level</span> <span className="stat-block"><b>Duration</b>: 1
round/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None or Will
negates; see text</span> <span className="stat-block"><b>Spell Resistance</b>:
No</span>
    <p>You discern all spells and magical properties present in a
number of creatures or objects. Each round, you may examine a single
creature or object that you can see as a free action. In the case of a
magic item, you learn its functions, how to activate its functions (if
appropriate), and how many charges are left (if it uses charges). In
the case of an object or creature with active spells cast upon it, you
learn each spell, its effect, and its caster level.</p>
    <p>An attended object may attempt a Will save to resist this
effect if its holder so desires. If the save succeeds, you learn
nothing about the object except what you can discern by looking at it.
An object that makes its save cannot be affected by any other analyze
dweomer spells for 24 hours.</p>
    <p>Analyze dweomer does not function when used on an artifact.</p>
    <p><i>Focus</i>: A tiny lens of ruby or sapphire set in a small
golden loop. The gemstone must be worth at least 1,500 gp.</p>
    <h6><a id="animal-growth">Animal Growth</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 5, Rgr 4, Sor/Wiz 5</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Targets</b>: Up to one
animal
(Gargantuan or smaller) per two levels, no two of which can be more
than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: 1
min./level</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>A number of animals grow to twice their normal size and eight
times their normal weight. This alteration changes each animal&rsquo;s
size
category to the next largest, grants it a +8 size bonus to Strength and
a +4 size bonus to Constitution (and thus an extra 2 hit points per
HD), and imposes a &ndash;2 size penalty to Dexterity. The
creature&rsquo;s
existing natural armor bonus increases by 2. The size change also
affects the animal&rsquo;s modifier to AC and attack rolls and its base
damage. The animal&rsquo;s space and reach change as appropriate to the
new
size, but its speed does not change.</p>
    <p>The spell also grants each subject <a style={{
        "color": "#579eb6"
      }} href="monsterTypes.html#DR">damage reduction</a> 10/magic
and a +4 resistance bonus on saving throws. If insufficient room is
available for the desired growth, the creature attains the maximum
possible size and may make a Strength check (using its increased
Strength) to burst any enclosures in the process. If it fails, it is
constrained without harm by the materials enclosing it&mdash; the spell
cannot be used to crush a creature by increasing its size.</p>
    <p>All equipment worn or carried by an animal is similarly
enlarged by the spell, though this change has no effect on the magical
properties of any such equipment.</p>
    <p>Any enlarged item that leaves the enlarged creature&rsquo;s
possession instantly returns to its normal size.</p>
    <p>The spell gives no means of command or influence over the
enlarged animals.</p>
    <p>Multiple magical effects that increase size do not stack.</p>
    <h6><a id="animal-messenger">Animal Messenger</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Drd 2, Rgr 1</span>
    <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One Tiny animal</span>
    <span className="stat-block"><b>Duration</b>: One day/level</span> <span className="stat-block"><b>Saving Throw</b>: None; see text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You compel a Tiny animal to go to a spot you designate. The
most common use for this spell is to get an animal to carry a message
to your allies. The animal cannot be one tamed or trained by someone
else, including such creatures as familiars and animal companions.</p>
    <p>Using some type of food desirable to the animal as a lure, you
call the animal to you. It advances and awaits your bidding. You can
mentally impress on the animal a certain place well known to you or an
obvious landmark. The directions must be simple, because the animal
depends on your knowledge and can&rsquo;t find a destination on its
own. You
can attach some small item or note to the messenger. The animal then
goes to the designated location and waits there until the duration of
the spell expires, whereupon it resumes its normal activities.</p>
    <p>During this period of waiting, the messenger allows others to
approach it and remove any scroll or token it carries. The intended
recipient gains no special ability to communicate with the animal or
read any attached message (if it&rsquo;s written in a language he or
she
doesn&rsquo;t know, for example).</p>
    <p><i>Material Component</i>: A morsel of food the animal likes.</p>
    <h6><a id="animal-shapes">Animal Shapes</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Animal 7, Drd 8</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: Up to one
willing
creature per level, all within 30 ft. of each other</span> <span className="stat-block"><b>Duration</b>: 1 hour/level (D)</span> <span className="stat-block"><b>Saving Throw</b>: None; see text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes (harmless)</span>
    <p>You transform up to one willing creature per caster level
into an animal of your choice; the spell has no effect on
unwilling creatures. Use the <a style={{
        "color": "#579eb6"
      }} href="monsterTypes.html#alternate-form">alternate form</a> special ability
to determine each target�s new
abilities. All creatures must take the same kind of animal
form. Recipients remain in the animal form until the spell expires or
until you dismiss it for all recipients. In addition, an individual
subject may choose to resume its normal form as a full-round action;
doing so ends the spell for that subject alone. The maximum HD of an
assumed form is equal to the subject&rsquo;s HD or your caster level,
whichever is lower, to a maximum of 20 HD at 20th level.</p>
    <h6><a id="animal-trance">Animal Trance</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting,
Sonic]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Drd 2</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: Animals or
magical
beasts with Intelligence 1 or 2</span> <span className="stat-block"><b>Duration</b>:
Concentration</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Your swaying motions and music (or singing, or chanting)
compel animals and magical beasts to do nothing but watch you. Only a
creature with an Intelligence score of 1 or 2 can be <a href="abilitiesAndConditions.html#fascinated" style={{
        "color": "rgb(87, 158, 182)"
      }}>fascinated</a> by this
spell. Roll 2d6 to determine the total number of HD worth of creatures
that you fascinate. The closest targets are selected first until no
more targets within range can be affected.</p>
    <p>A magical beast, a dire animal, or an animal trained to attack
or guard is allowed a saving throw; an animal not trained to attack or
guard is not.</p>
    <h6><a id="animate-dead">Animate Dead</a></h6>
    <p className="initial"><i>Necromancy [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3, Death 3, Sor/Wiz 4</span>
    <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
One or more corpses
touched</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span>
    <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell turns the bones or bodies of <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a> creatures into
undead skeletons or zombies that follow your spoken commands.</p>
    <p>The undead can follow you, or they can remain in an area and
attack any creature (or just a specific kind of creature) entering the
place. They remain animated until they are destroyed. (A destroyed
skeleton or zombie can&rsquo;t be animated again.)</p>
    <p>Regardless of the type of undead you create with this spell,
you can&rsquo;t create more HD of undead than twice your caster level
with a
single casting of animate dead. (The desecrate spell doubles this limit)</p>
    <p>The undead you create remain under your control indefinitely.
No matter how many times you use this spell, however, you can control
only 4 HD worth of undead creatures per caster level. If you exceed
this number, all the newly created creatures fall under your control,
and any excess undead from previous castings become uncontrolled. (You
choose which creatures are released.) If you are a cleric, any undead
you might command by virtue of your power to command or rebuke undead
do not count toward the limit.</p>
    <p><i>Skeletons</i>: A skeleton can be created only from a mostly
intact corpse or skeleton. The corpse must have bones. If a skeleton is
made from a corpse, the flesh falls off the bones. </p>
    <p><i>Zombies</i>: A zombie can be created only from a mostly
intact corpse. The corpse must be that of a creature with a true
anatomy.</p>
    <p><i>Material Component</i>: You must place a black onyx gem
worth at least 25 gp per Hit Die of the undead into the mouth or eye
socket of each corpse you intend to animate. The magic of the spell
turns these gems into worthless, burned-out shells.</p>
    <h6><a id="animate-objects">Animate Objects</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Chaos 6, Clr 6</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Targets</b>: One Small
object per
caster level; see text</span> <span className="stat-block"><b>Duration</b>:
1 round/level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You imbue inanimate objects with mobility and a semblance of
life. Each such animated object then immediately attacks whomever or
whatever you initially designate.</p>
    <p>An animated object can be of any nonmagical material. You may
animate one Small or smaller object or an equivalent number of larger
objects per caster level. A Medium object counts as two Small or
smaller objects, a Large object as four, a Huge object as eight, a
Gargantuan object as sixteen, and a Colossal object as thirty-two. You
can change the designated target or targets as a move action, as if
directing an active spell.</p>
    <p>This spell cannot animate objects carried or worn by a
creature.</p>
    <p>Animate objects can be made permanent with a permanency spell.</p>
    <h6><a id="animate-plants">Animate Plants</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 7, Plant 7</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One Large
plant per
three caster levels or all plants within range; see text</span> <span className="stat-block"><b>Duration</b>: 1 round/level or 1
hour/level; see text</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You imbue inanimate plants with mobility and a semblance of
life. Each animated plant then immediately attacks whomever or whatever
you initially designate as though it were an animated object of the
appropriate size category. You may animate one Large or smaller plant,
or an equivalent number of larger plants, per three caster levels. A
Huge plant counts as two Large or smaller plants, a Gargantuan plant as
four, and a Colossal plant as eight. You can change the designated
target or targets as a move action, as if directing an active spell.</p>
    <p>Use the statistics for animated objects, except that plants
smaller than Large usually don&rsquo;t have hardness.</p>
    <p>Animate plants cannot affect plant creatures, nor does it
affect nonliving vegetable material.</p>
    <p><i>Entangle</i>: Alternatively, you may imbue all plants
within range with a degree of mobility, which allows them to entwine
around creatures in the area. This usage of the spell duplicates the
effect of an entangle spell. Spell resistance does not keep creatures
from being <a href="abilitiesAndConditions.html#entangled" style={{
        "color": "rgb(87, 158, 182)"
      }}>entangled</a>. This effect lasts 1
hour per caster level.</p>
    <h6><a id="animate-rope">Animate Rope</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Target</b>: One ropelike
object,
length up to 50 ft. + 5 ft./level; see text</span> <span className="stat-block"><b>Duration</b>: 1 round/level</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You can animate a nonliving ropelike object. The maximum
length assumes a rope with a 1-inch diameter.</p>
    <p>Reduce the maximum length by 50% for every additional inch of
thickness, and increase it by 50% for each reduction of the
rope&rsquo;s
diameter by half.</p>
    <p>The possible commands are &ldquo;coil&rdquo; (form a neat,
coiled stack),
&ldquo;coil and knot,&rdquo; &ldquo;loop,&rdquo; &ldquo;loop and
knot,&rdquo; &ldquo;tie and knot,&rdquo; and the
opposites of all of the above (&ldquo;uncoil,&rdquo; and so forth). You
can give
one command each round as a move action, as if directing an active
spell.</p>
    <p>The rope can enwrap only a creature or an object within 1 foot
of it&mdash;it does not snake outward&mdash;so it must be thrown near
the intended
target. Doing so requires a successful ranged touch attack roll (range
increment 10 feet). A typical 1- inch-diameter hempen rope has 2 hit
points, AC 10, and requires a DC 23 Strength check to burst it. The
rope does not deal damage, but it can be used as a trip line or to
cause a single opponent that fails a Reflex saving throw to become <a href="abilitiesAndConditions.html#entangled" style={{
        "color": "rgb(87, 158, 182)"
      }}>entangled</a>. A creature capable of
spellcasting that is bound by this
spell must make a DC 15 <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check to cast a
spell. An entangled creature can slip free with a DC 20 <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape
Artist</a>
check.</p>
    <p>The rope itself and any knots tied in it are not magical.</p>
    <p>This spell grants a +2 bonus on any <a href="skillsAll.html#use-rope" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use
Rope</a> checks you make
when using the transmuted rope.</p>
    <p>The spell cannot animate objects carried or worn by a creature.</p>
    <h6><a id="antilife-shell">Antilife Shell</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Animal 6, Clr 6, Drd 6</span>
    <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: 10 ft.</span> <span className="stat-block"><b>Area</b>: 10-ft.-radius emanation,
centered on you</span> <span className="stat-block"><b>Duration</b>: 10
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You bring into being a mobile, hemispherical energy field that
prevents the entrance of most types of living creatures.</p>
    <p>The effect hedges out animals, aberrations, dragons, fey,
giants, humanoids, magical beasts, monstrous humanoids, oozes, plants,
and vermin, but not constructs, elementals, outsiders, or undead.</p>
    <p>This spell may be used only defensively, not aggressively.
Forcing an abjuration barrier against creatures that the spell keeps at
bay collapses the barrier.</p>
    <h6><a id="antimagic-field">Antimagic Field</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 8, Magic 6, Protection
6, Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S,
M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard
action</span> <span className="stat-block"><b>Range</b>: 10 ft.</span> <span className="stat-block"><b>Area</b>: 10-ft.-radius emanation,
centered on you</span> <span className="stat-block"><b>Duration</b>: 10
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: See text</span>
    <p>An <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> barrier surrounds you
and moves with you. The
space within this barrier is impervious to most magical effects,
including spells, spell-like abilities, and supernatural abilities.
Likewise, it prevents the functioning of any magic items or spells
within its confines.</p>
    <p>An antimagic field suppresses any spell or magical effect used
within, brought into, or cast into the area, but does not dispel it.
Time spent within an antimagic field counts against the suppressed
spell&rsquo;s duration.</p>
    <p>Summoned creatures of any type and <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a> undead wink out
if they enter an antimagic field. They reappear in the same spot once
the field goes away. Time spent winked out counts normally against the
duration of the conjuration that is maintaining the creature. If you
cast antimagic field in an area occupied by a summoned creature that
has spell resistance, you must make a caster level check (1d20 + caster
level) against the creature&rsquo;s spell resistance to make it wink
out.
(The effects of instantaneous conjurations are not affected by an
antimagic field because the conjuration itself is no longer in effect,
only its result.)</p>
    <p>A normal creature can enter the area, as can normal missiles.
Furthermore, while a magic sword does not function magically within the
area, it is still a sword (and a masterwork sword at that). The spell
has no effect on golems and other constructs that are imbued with magic
during their creation process and are thereafter self-supporting
(unless they have been summoned, in which case they are treated like
any other summoned creatures). Elementals, corporeal undead, and
outsiders are likewise unaffected unless summoned. These
creatures&rsquo;
spell-like or supernatural abilities, however, may be temporarily
nullified by the field. Dispel magic does not remove the field.</p>
    <p>Two or more antimagic fields sharing any of the same space
have no effect on each other. Certain spells, such as wall of force,
prismatic sphere, and prismatic wall, remain unaffected by antimagic
field (see the individual spell descriptions). Artifacts and deities
are unaffected by mortal magic such as this. </p>
    <p>Should a creature be larger than the area enclosed by the
barrier, any part of it that lies outside the barrier is unaffected by
the field.</p>
    <p><i>Arcane Material Component</i>: A pinch of powdered iron or
iron filings.</p>
    <h6><a id="antipathy">Antipathy</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 9, Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 hour</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One location
(up to a
10-ft. cube/level) or one object</span> <span className="stat-block"><b>Duration</b>:
2 hours/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will partial</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>You cause an object or location to emanate magical vibrations
that repel either a specific kind of intelligent creature or creatures
of a particular alignment, as defined by you. The kind of creature to
be affected must be named specifically. A creature subtype is not
specific enough. Likewise, the specific alignment to be repelled must
be named.</p>
    <p>Creatures of the designated kind or alignment feel an
overpowering urge to leave the area or to avoid the affected item.</p>
    <p>A compulsion forces them to abandon the area or item, shunning
it and never willingly returning to it while the spell is in effect. A
creature that makes a successful saving throw can stay in the area or
touch the item but feels uncomfortable doing so. This distracting
discomfort reduces the creature&rsquo;s Dexterity score by 4 points.</p>
    <p>Antipathy counters and dispels sympathy.</p>
    <p><i>Arcane Material Component</i>: A lump of alum soaked in
vinegar.</p>
    <h6><a id="antiplant-shell">Antiplant Shell</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Drd 4</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 10 ft.</span> <span className="stat-block"><b>Area</b>: 10-ft.-radius emanation,
centered on you</span> <span className="stat-block"><b>Duration</b>: 10
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The antiplant shell spell creates an <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>, mobile barrier
that keeps all creatures within the shell protected from attacks by
plant creatures or animated plants. As with many abjuration spells,
forcing the barrier against creatures that the spell keeps at bay
strains and collapses the field.</p>
    <h6><a id="arcane-eye">Arcane Eye</a></h6>
    <p className="initial"><i>Divination (Scrying)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Unlimited</span> <span className="stat-block"><b>Effect</b>: Magical sensor</span> <span className="stat-block"><b>Duration</b>: 1 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You create an <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> magical sensor that
sends you visual
information. You can create the arcane eye at any point you can see,
but it can then travel outside your line of sight without hindrance. An
arcane eye travels at 30 feet per round (300 feet per minute) if
viewing an area ahead as a human would (primarily looking at the floor)
or 10 feet per round (100 feet per minute) if examining the ceiling and
walls as well as the floor ahead. It sees exactly as you would see if
you were there.</p>
    <p>The eye can travel in any direction as long as the spell
lasts. Solid barriers block its passage, but it can pass through a hole
or space as small as 1 inch in diameter. The eye can&rsquo;t enter
another
plane of existence, even through a gate or similar magical portal.</p>
    <p>You must concentrate to use an arcane eye. If you do not
concentrate, the eye is inert until you again concentrate.</p>
    <p><i>Material Component</i>: A bit of bat fur.</p>
    <h6><a id="arcane-lock">Arcane Lock</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
The door, chest, or
portal touched, up to 30 sq. ft./level in size</span> <span className="stat-block"><b>Duration</b>: Permanent</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>An arcane lock spell cast upon a door, chest, or portal
magically locks it. You can freely pass your own arcane lock without
affecting it; otherwise, a door or object secured with this spell can
be opened only by breaking in or with a successful dispel magic or
knock spell. Add 10 to the normal DC to break open a door or portal
affected by this spell. (A knock spell does not remove an arcane lock;
it only suppresses the effect for 10 minutes.)</p>
    <p><i>Material Component</i>: Gold dust worth 25 gp.</p>
    <h6><a id="arcane-mark">Arcane Mark</a></h6>
    <p className="initial"><i>Universal</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 0</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 0 ft.</span> <span className="stat-block"><b>Effect</b>:
One personal rune or
mark, all of which must fit within 1 sq. ft.</span> <span className="stat-block"><b>Duration</b>: Permanent</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell allows you to inscribe your personal rune or mark,
which can consist of no more than six characters. The writing can be
visible or <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>. An arcane mark spell
enables you to etch the rune
upon any substance without harm to the material upon which it is
placed. If an invisible mark is made, a detect magic spell causes it to
glow and be visible, though not necessarily understandable.</p>
    <p>See invisibility, true seeing, a gem of seeing, or a robe of
eyes likewise allows the user to see an invisible arcane mark. A read
magic spell reveals the words, if any. The mark cannot be dispelled,
but it can be removed by the caster or by an erase spell.</p>
    <p>If an arcane mark is placed on a living being, normal wear
gradually causes the effect to fade in about a month.</p>
    <p>Arcane mark must be cast on an object prior to casting instant
summons on the same object (see that spell description for details).</p>
    <h6><a id="arcane-sight">Arcane Sight</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 min./level (D)</span>
    <p>This spell makes your eyes glow blue and allows you to see
magical auras within 120 feet of you. The effect is similar to that of
a detect magic spell, but arcane sight does not require concentration
and discerns aura location and power more quickly.</p>
    <p>You know the location and power of all magical auras within
your sight. An aura&rsquo;s power depends on a spell&rsquo;s
functioning level or
an item&rsquo;s caster level, as noted in the description of the detect
magic
spell. If the items or creatures bearing the auras are in line of
sight, you can make <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a> skill checks to
determine the school of
magic involved in each. (Make one check per aura; DC 15 + spell level,
or 15 + one-half caster level for a nonspell effect.)</p>
    <p>If you concentrate on a specific creature within 120 feet of
you as a standard action, you can determine whether it has any
spellcasting or spell-like abilities, whether these are arcane or
divine (spell-like abilities register as arcane), and the strength of
the most powerful spell or spell-like ability the creature currently
has available for use.</p>
    <p>Arcane sight can be made permanent with a permanency spell.</p>
    <h6><a id="greater-arcane-sight">Arcane Sight, Greater</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#arcane-sight">arcane sight</a>, except that you
automatically know which spells or magical effects are active upon any
individual or object you see.</p>
    <p>Greater arcane sight doesn&rsquo;t let you identify magic
items.</p>
    <p>Unlike arcane sight, this spell cannot be made permanent with
a permanency spell.</p>
    <h6><a id="astral-projection">Astral Projection</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 9, Sor/Wiz 9, Travel 9</span>
    <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 30 minutes</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
You plus one additional
willing creature touched per two caster levels</span> <span className="stat-block"><b>Duration</b>: See text</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>By freeing your spirit from your physical body, this spell
allows you to project an astral body onto another plane altogether.</p>
    <p>You can bring the astral forms of other willing creatures with
you, provided that these subjects are linked in a circle with you at
the time of the casting. These fellow travelers are dependent upon you
and must accompany you at all times. If something happens to you during
the journey, your companions are stranded wherever you left them.</p>
    <p>You project your astral self onto the Astral Plane, leaving
your physical body behind on the Material Plane in a state of suspended
animation. The spell projects an astral copy of you and all you wear or
carry onto the Astral Plane. Since the Astral Plane touches upon other
planes, you can travel astrally to any of these other planes as you
will. To enter one, you leave the Astral Plane, forming a new physical
body (and equipment) on the plane of existence you have chosen to enter.</p>
    <p>While you are on the Astral Plane, your astral body is
connected at all times to your physical body by a silvery cord. If the
cord is broken, you are killed, astrally and physically. Luckily, very
few things can destroy a silver cord. When a second body is formed on a
different plane, the <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a> silvery cord remains
invisibly
attached to the new body. If the second body or the astral form is
slain, the cord simply returns to your body where it rests on the
Material Plane, thereby reviving it from its state of suspended
animation. Although astral projections are able to function on the
Astral Plane, their actions affect only creatures existing on the
Astral Plane; a physical body must be materialized on other planes.</p>
    <p>You and your companions may travel through the Astral Plane
indefinitely. Your bodies simply wait behind in a state of suspended
animation until you choose to return your spirits to them. The spell
lasts until you desire to end it, or until it is terminated by some
outside means, such as dispel magic cast upon either the physical body
or the astral form, the breaking of the silver cord, or the destruction
of your body back on the Material Plane (which kills you).</p>
    <p><i>Material Component</i>: A jacinth worth at least 1,000 gp,
plus a silver bar worth 5 gp for each person to be affected.</p>
    <h6><a id="atonement">Atonement</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5, Drd 5</span> <span className="stat-block"><b>Components</b>: V, S, M, F, DF, XP</span> <span className="stat-block"><b>Casting Time</b>: 1 hour</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Living creature touched</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell removes the burden of evil acts or misdeeds from
the subject. The creature seeking atonement must be truly repentant and
desirous of setting right its misdeeds. If the atoning creature
committed the evil act unwittingly or under some form of compulsion,
atonement operates normally at no cost to you. However, in the case of
a creature atoning for deliberate misdeeds and acts of a knowing and
willful nature, you must intercede with your deity (requiring you to
expend 500 XP) in order to expunge the subject&rsquo;s burden. Many
casters
first assign a subject of this sort a quest (see geas/quest) or similar
penance to determine whether the creature is truly contrite before
casting the atonement spell on its behalf.</p>
    <p>Atonement may be cast for one of several purposes, depending
on the version selected.</p>
    <p><i>Reverse Magical Alignment Change</i>: If a creature has had
its alignment magically changed, atonement returns its alignment to its
original status at no cost in experience points.</p>
    <p><i>Restore Class</i>: A paladin who has lost her class
features due to committing an evil act may have her paladinhood
restored to her by this spell.</p>
    <p><i>Restore Cleric or Druid Spell Powers</i>: A cleric or druid
who has lost the ability to cast spells by incurring the anger of his
or her deity may regain that ability by seeking atonement from another
cleric of the same deity or another druid. If the transgression was
intentional, the casting cleric loses 500 XP for his intercession. If
the transgression was unintentional, he does not lose XP.</p>
    <p><i>Redemption or Temptation</i>: You may cast this spell upon
a creature of an opposing alignment in order to offer it a chance to
change its alignment to match yours. The prospective subject must be
present for the entire casting process. Upon completion of the spell,
the subject freely chooses whether it retains its original alignment or
acquiesces to your offer and changes to your alignment. No duress,
compulsion, or magical influence can force the subject to take
advantage of the opportunity offered if it is unwilling to abandon its
old alignment. This use of the spell does not work on outsiders or any
creature incapable of changing its alignment naturally.</p>
    <p>Though the spell description refers to evil acts, atonement
can also be used on any creature that has performed acts against its
alignment, whether those acts are evil, good, chaotic, or lawful.</p>
    <p><i>Note</i>: Normally, changing alignment is up to the player.
This use of atonement simply offers a believable way for a character to
change his or her alignment drastically, suddenly, and definitively.</p>
    <p><i>Material Component</i>: Burning incense.</p>
    <p><i>Focus</i>: In addition to your holy symbol or normal divine
focus, you need a set of prayer beads (or other prayer device, such as
a prayer wheel or prayer book) worth at least 500 gp.</p>
    <p><i>XP Cost</i>: When cast for the benefit of a creature whose
guilt was the result of deliberate acts, the cost to you is 500 XP per
casting (see above).</p>
    <h6><a id="augury">Augury</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2</span> <span className="stat-block"><b>Components</b>: V, S, M, F</span> <span className="stat-block"><b>Casting Time</b>: 1 minute</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span>
    <p>An augury can tell you whether a particular action will bring
good or bad results for you in the immediate future.</p>
    <p>The base chance for receiving a meaningful reply is 70% + 1%
per caster level, to a maximum of 90%; this roll is made secretly. A
question may be so straightforward that a successful result is
automatic, or so vague as to have no chance of success. If the augury
succeeds, you get one of four results:</p>
    <ul>
      <li>
        <p>Weal (if the action will probably bring good results).</p>
      </li>
      <li>
        <p>Woe (for bad results).</p>
      </li>
      <li>
        <p>Weal and woe (for both).</p>
      </li>
      <li>
        <p>Nothing (for actions that don&rsquo;t have especially good
or
bad results).</p>
      </li>
    </ul>
    <p>If the spell fails, you get the &ldquo;nothing&rdquo; result.
A cleric who
gets the &ldquo;nothing&rdquo; result has no way to tell whether it was
the
consequence of a failed or successful augury.</p>
    <p>The augury can see into the future only about half an hour, so
anything that might happen after that does not affect the result. Thus,
the result might not take into account the long-term consequences of a
contemplated action. All auguries cast by the same person about the
same topic use the same dice result as the first casting.</p>
    <p><i>Material Component</i>: Incense worth at least 25 gp.</p>
    <p><i>Focus</i>: A set of marked sticks, bones, or similar tokens
of at least 25 gp value.</p>
    <h6><a id="awaken">Awaken</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 5</span> <span className="stat-block"><b>Components</b>: V, S, DF, XP</span> <span className="stat-block"><b>Casting Time</b>: 24 hours</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Animal or tree touched</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>You awaken a tree or animal to humanlike sentience. To
succeed, you must make a Will save (DC 10 + the animal&rsquo;s current
HD, or
the HD the tree will have once awakened).</p>
    <p>The awakened animal or tree is friendly toward you. You have
no special empathy or connection with a creature you awaken, although
it serves you in specific tasks or endeavors if you communicate your
desires to it.</p>
    <p>An awakened tree has characteristics as if it were an animated
object, except that it gains the plant type and its Intelligence,
Wisdom, and Charisma scores are each 3d6. An awakened plant gains the
ability to move its limbs, roots, vines, creepers, and so forth, and it
has senses similar to a human&rsquo;s.</p>
    <p>An awakened animal gets 3d6 Intelligence, +1d3 Charisma, and
+2 HD. Its type becomes magical beast (augmented animal). An awakened
animal can&rsquo;t serve as an animal companion, familiar, or special
mount.</p>
    <p>An awakened tree or animal can speak one language that you
know, plus one additional language that you know per point of
Intelligence bonus (if any).</p>
    <p><i>XP Cost</i>: 250 XP.</p>
    <h6><a id="baleful-polymorph">Baleful Polymorph</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 5, Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One creature</span>
    <span className="stat-block"><b>Duration</b>: Permanent</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude negates,
Will partial; see text</span> <span className="stat-block"><b>Spell
Resistance</b>: Yes</span>
    <p>You change the subject into a Small or smaller animal of
no more than 1 HD (such as a dog, lizard, monkey, or
toad). The subject takes on all the statistics and special
abilities of an average member of the new form in place
of its own except as follows:</p>
    <ul><li><p>The target retains its own alignment (and
personality, within the limits of the new form�s
ability scores).</p></li>
      <li><p>If the target has the shapechanger subtype, it
retains that subtype.</p></li>
      <li><p>The target retains its own hit points.</p></li>
      <li><p>The target is treated has having its normal Hit
Dice for purpose of adjudicating effects based
on HD, such as the sleep spell, though it uses
the new form�s base attack bonus, base save
bonuses, and all other statistics derived from Hit
Dice.</p></li>
      <li><p>The target also retains the ability to understand
(but not to speak) the languages it understood in
its original form. It can write in the languages it
understands, but only the form is capable of
writing in some manner (such as drawing in the
dirt with a paw).</p></li></ul>
    <p>With those exceptions, the target�s normal
game statistics are replaced by those of the new
form. The target loses all the special abilities it
has in its normal form, including its class
features.</p>
    <p>All items worn or carried by the subject fall to
the ground at its feet, even if they could be worn
or carried by the new form.</p>
    <p>If the new form would prove fatal to the
creature (for example, if you polymorphed a
landbound target into a fish, or an airborne
target into a toad), the subject gets a +4 bonus
on the save.</p>
    <p>If the subject remains in the new form for 24
consecutive hours, it must attempt a Will save.
If this save fails, it loses its ability to understand
language, as well as all other memories of its
previous form, and its Hit Dice and hit points
change to match an average creature of its new
form. These abilities and statistics return to
normal if the effect is later ended.</p>
    <p>Incorporeal or gaseous creatures are immune
to baleful polymorph, and a creature with the
shapechanger subtype (such as a lycanthrope or
a doppelganger) can revert to its natural form as
a standard action (which ends the spell�s effect).</p>
    <h6><a id="bane">Bane</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Fear,
Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 50 ft.</span> <span className="stat-block"><b>Area</b>: All enemies within 50 ft.</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Bane fills your enemies with fear and doubt. Each affected
creature takes a &ndash;1 penalty on attack rolls and a &ndash;1
penalty on saving
throws against fear effects.</p>
    <p>Bane counters and dispels bless.</p>
    <h6><a id="banishment">Banishment</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6, Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One or more
extraplanar
creatures, no two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Will negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>A banishment spell is a more powerful version of the <a style={{
        "color": "#579eb6"
      }} href="spellsDtoE.html#dismissal">dismissal</a>
spell. It enables you to force extraplanar creatures out of your home
plane. As many as 2 Hit Dice of creatures per caster level can be
banished.</p>
    <p>You can improve the spell&rsquo;s chance of success by
presenting at
least one object or substance that the target hates, fears, or
otherwise opposes. For each such object or substance, you gain a +1
bonus on your caster level check to overcome the target&rsquo;s spell
resistance (if any), the saving throw DC increases by 2. </p>
    <p>Certain rare items might work twice as well as a normal item
for the purpose of the bonuses (each providing a +2 bonus on the caster
level check against spell resistance and increasing the save DC by 4).</p>
    <p><i>Arcane Focus</i>: Any item that is distasteful to the
subject (optional, see above).</p>
    <h6><a id="barkskin">Barkskin</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 2, Rgr 2, Plant 2</span>
    <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Living creature touched</span> <span className="stat-block"><b>Duration</b>:
10 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes (harmless)</span>
    <p>Barkskin toughens a creature&rsquo;s skin. The effect grants a
+2
enhancement bonus to the creature&rsquo;s existing natural armor bonus.
This
enhancement bonus increases by 1 for every three caster levels above
3rd, to a maximum of +5 at caster level 12th.</p>
    <p>The enhancement bonus provided by barkskin stacks with the
target&rsquo;s natural armor bonus, but not with other enhancement
bonuses to
natural armor. A creature without natural armor has an effective
natural armor bonus of +0.</p>
    <h6><a id="bears-endurance">Bear&rsquo;s Endurance</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2, Drd 2, Rgr 2,
Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Creature touched</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The affected creature gains greater vitality and stamina. The
spell grants the subject a +4 enhancement bonus to Constitution, which
adds the usual benefits to hit points, Fortitude saves, Constitution
checks, and so forth.</p>
    <p>Hit points gained by a temporary increase in Constitution
score are not temporary hit points. They go away when the
subject&rsquo;s
Constitution drops back to normal. They are not lost first as temporary
hit points are.</p>
    <h6><a id="mass-bears-endurance">Bear&rsquo;s Endurance, Mass</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6, Drd 6, Sor/Wiz 6</span>
    <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One
creature/level, no
two of which can be more than 30 ft. apart</span>
    <p>Mass bear &rsquo;s endurance works like <a style={{
        "color": "#579eb6"
      }} href="#bears-endurance">bear&rsquo;s
endurance</a>, except
that it affects multiple creatures.</p>
    <h6><a id="bestow-curse">Bestow Curse</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3, Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>:
Permanent</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You place a curse on the subject. Choose one of the following
three effects.</p>
    <ul>
      <li>
        <p>&ndash;6 decrease to an ability score (minimum 1).</p>
      </li>
      <li>
        <p>&ndash;4 penalty on attack rolls, saves, ability checks,
and
skill checks.</p>
      </li>
      <li>
        <p>Each turn, the target has a 50% chance to act normally;
otherwise, it takes no action.</p>
      </li>
    </ul>
    <p>You may also invent your own curse, but it should be no more
powerful than those described above.</p>
    <p>The curse bestowed by this spell cannot be dispelled, but it
can be removed with a break enchantment, limited wish, miracle, remove
curse, or wish spell.</p>
    <p>Bestow curse counters remove curse.</p>
    <h6><a id="binding">Binding</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: One minute</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One living
creature</span> <span className="stat-block"><b>Duration</b>: See text (D)</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>A binding spell creates a magical restraint to hold a
creature. The target gets an initial saving throw only if its Hit Dice
equal at least one-half your caster level.</p>
    <p>You may have as many as six assistants help you with the
spell. For each assistant who casts suggestion, your caster level for
this casting of binding increases by 1. For each assistant who casts
dominate animal, dominate person, or dominate monster, your caster
level for this casting of binding increases by a number equal to
one-third of that assistant&rsquo;s level, provided that the
spell&rsquo;s target
is appropriate for a binding spell. Since the assistants&rsquo; spells
are
cast simply to improve your caster level for the purpose of the binding
spell, saving throws and spell resistance against the assistants&rsquo;
spells are irrelevant. Your caster level determines whether the target
gets an initial Will saving throw and how long the binding lasts. All
binding spells are dismissible.</p>
    <p>Regardless of the version of binding you cast, you can specify
triggering conditions that end the spell and release the creature
whenever they occur. These triggers can be as simple or elaborate as
you desire, but the condition must be reasonable and have a likelihood
of coming to pass. The conditions can be based on a creature&rsquo;s
name,
identity, or alignment but otherwise must be based on observable
actions or qualities. Intangibles such as level, class, Hit Dice, or
hit points don&rsquo;t qualify. Once the spell is cast, its triggering
conditions cannot be changed. Setting a release condition increases the
save DC (assuming a saving throw is allowed) by 2.</p>
    <p>If you are casting any of the first three versions of binding
(those with limited durations), you may cast additional binding spells
to prolong the effect, since the durations overlap. If you do so, the
target gets a saving throw at the end of the first spell&rsquo;s
duration,
even if your caster level was high enough to disallow an initial saving
throw. If the creature succeeds on this save, all the binding spells it
has received are broken.</p>
    <p>The binding spell has six versions. Choose one of the
following versions when you cast the spell.</p>
    <p><i>Chaining</i>: The subject is confined by restraints that
generate an antipathy spell affecting all creatures who approach the
subject, except you. The duration is one year per caster level. The
subject of this form of binding is confined to the spot it occupied
when it received the spell.</p>
    <p><i>Slumber</i>: This version causes the subject to become
comatose for as long as one year per caster level. The subject does not
need to eat or drink while slumbering, nor does it age. This form of
binding is more difficult to cast than chaining, making it slightly
easier to resist. Reduce the spell&rsquo;s save DC by 1.</p>
    <p><i>Bound Slumber</i>: This combination of chaining and slumber
lasts for as long as one month per caster level. Reduce the save DC by
2.</p>
    <p><i>Hedged Prison</i>: The subject is transported to or
otherwise brought within a confined area from which it cannot wander by
any means. The effect is permanent. Reduce the save DC by 3.</p>
    <p><i>Metamorphosis</i>: The subject assumes gaseous form, except
for its head or face. It is held harmless in a jar or other container,
which may be transparent if you so choose. The creature remains aware
of its surroundings and can speak, but it cannot leave the container,
attack, or use any of its powers or abilities. The binding is
permanent. The subject does not need to breathe, eat, or drink while
metamorphosed, nor does it age. Reduce the save DC by 4.</p>
    <p><i>Minimus Containment</i>: The subject is shrunk to a height
of 1 inch or even less and held within some gem, jar, or similar
object. The binding is permanent. The subject does not need to breathe,
eat, or drink while contained, nor does it age. Reduce the save DC by 4.</p>
    <p>You can&rsquo;t dispel a binding spell with dispel magic or a
similar effect, though an antimagic field or Mage&rsquo;s disjunction
affects
it normally. A bound extraplanar creature cannot be sent back to its
home plane due to dismissal, banishment, or a similar effect.</p>
    <p><i>Components</i>: The components for a binding spell vary
according to the version of the spell, but they always include a
continuous chanting utterance read from the scroll or spellbook page
containing the spell, somatic gestures, and materials appropriate to
the form of binding used. These components can include such items as
miniature chains of special metals, soporific herbs of the rarest sort
(for slumber bindings), a bell jar of the finest crystal, and the like.</p>
    <p>In addition to the specially made props suited to the specific
type of binding (cost 500 gp), the spell requires opals worth at least
500 gp for each HD of the target and a vellum depiction or carved
statuette of the subject to be captured.</p>
    <h6><a id="black-tentacles">Black Tentacles</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: 20-ft.-radius
spread</span> <span className="stat-block"><b>Duration</b>: 1 round/level
(D)</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell conjures a field of rubbery black tentacles, each
10 feet long. These waving members seem to spring forth from the earth,
floor, or whatever surface is underfoot&mdash;including water. They
grasp and
entwine around creatures that enter the area, holding them fast and
crushing them with great strength.</p>
    <p>Every creature within the area of the spell must make a
grapple check, opposed by the <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a> check of the tentacles.
Treat the
tentacles attacking a particular target as a Large creature with a base
attack bonus equal to your caster level and a Strength score of 19.
Thus, its grapple check modifier is equal to your caster level +8. The
tentacles are immune to all types of damage.</p>
    <p>Once the tentacles grapple an opponent, they may make a
grapple check each round on your turn to deal 1d6+4 points of
bludgeoning damage. The tentacles continue to crush the opponent until
the spell ends or the opponent escapes.</p>
    <p>Any creature that enters the area of the spell is immediately
attacked by the tentacles. Even creatures who aren&rsquo;t <a href="abilitiesAndConditions.html#grappling" style={{
        "color": "rgb(87, 158, 182)"
      }}>grappling</a> with the
tentacles may move through the area at only half normal speed.</p>
    <p><i>Material Component</i>: A piece of tentacle from a giant
octopus or a giant squid.</p>
    <h6><a id="blade-barrier">Blade Barrier</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6, Good 6, War 6</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Wall of
whirling blades
up to 20 ft. long/ level, or a ringed wall of whirling blades with a
radius of up to 5 ft. per two levels; either form 20 ft. high</span> <span className="stat-block"><b>Duration</b>: 1 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>: Reflex half or
Reflex negates; see text</span> <span className="stat-block"><b>Spell
Resistance</b>: Yes</span>
    <p>An immobile, vertical curtain of whirling blades shaped of
pure force springs into existence. Any creature passing through the
wall takes 1d6 points of damage per caster level (maximum 15d6), with a
Reflex save for half damage.</p>
    <p>If you evoke the barrier so that it appears where creatures
are, each creature takes damage as if passing through the wall. Each
such creature can avoid the wall (ending up on the side of its choice)
and thus take no damage by making a successful Reflex save.</p>
    <p>A blade barrier provides cover (+4 bonus to AC, +2 bonus on
Reflex saves) against attacks made through it.</p>
    <h6><a id="blasphemy">Blasphemy</a></h6>
    <p className="initial"><i>Evocation [Evil, Sonic]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 7, Evil 7</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 40 ft.</span> <span className="stat-block"><b>Area</b>: Nonevil creatures in a
40-ft.-radius spread centered on you</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
None or Will
negates; see text</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>Any nonevil creature within the area of a blasphemy spell
suffers the following ill effects.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "150px"
          }}>HD</th>
          <th>Effect</th>
        </tr>
        <tr className="odd-row">
          <td>Equal to caster level</td>
          <td><a href="abilitiesAndConditions.html#dazed" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dazed</a></td>
        </tr>
        <tr>
          <td>Up to caster level &ndash;1</td>
          <td>Weakened, dazed</td>
        </tr>
        <tr className="odd-row">
          <td>Up to caster level &ndash;5</td>
          <td><a href="abilitiesAndConditions.html#paralyzed" style={{
              "color": "rgb(87, 158, 182)"
            }}>Paralyzed</a>, weakened, dazed</td>
        </tr>
        <tr>
          <td className="last-row">Up to caster level &ndash;10</td>
          <td className="last-row">Killed, paralyzed, weakened, dazed</td>
        </tr>
      </tbody>
    </table>
    <p>The effects are cumulative and concurrent.</p>
    <p>No saving throw is allowed against these effects.</p>
    <p><i>Dazed</i>: The creature can take no actions for 1 round,
though it defends itself normally.</p>
    <p><i>Weakened</i>: The creature&rsquo;s Strength score decreases
by
2d6 points for 2d4 rounds.</p>
    <p><i>Paralyzed</i>: The creature is paralyzed and <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a> for
1d10 minutes.</p>
    <p><i>Killed</i>: Living creatures die. Undead creatures are
destroyed.</p>
    <p>Furthermore, if you are on your home plane when you cast this
spell, nonevil extraplanar creatures within the area are instantly
banished back to their home planes. Creatures so banished cannot return
for at least 24 hours. This effect takes place regardless of whether
the creatures hear the blasphemy. The banishment effect allows a Will
save (at a &ndash;4 penalty) to negate.</p>
    <p>Creatures whose Hit Dice exceed your caster level are
unaffected by blasphemy.</p>
    <h6><a id="bless">Bless</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1, Pal 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 50 ft.</span> <span className="stat-block"><b>Area</b>: The caster and all allies
within a 50-ft. burst, centered on the caster</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: Yes (harmless)</span>
    <p>Bless fills your allies with courage. Each ally gains a +1
morale bonus on attack rolls and on saving throws against fear effects.</p>
    <p>Bless counters and dispels bane.</p>
    <h6><a id="bless-water">Bless Water</a></h6>
    <p className="initial"><i>Transmutation [Good]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1, Pal 1</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 minute</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Flask of water touched</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>This transmutation imbues a flask (1 pint) of water with
positive energy, turning it into holy water.</p>
    <p><i>Material Component</i>: 5 pounds of powdered silver (worth
25 gp).</p>
    <h6><a id="bless-weapon">Bless Weapon</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Pal 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Weapon touched</span> <span className="stat-block"><b>Duration</b>: 1
min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This transmutation makes a weapon strike true against evil
foes. The weapon is treated as having a +1 enhancement bonus for the
purpose of bypassing the <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a> of evil creatures or striking
evil <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a> creatures (though
the spell doesn&rsquo;t grant an actual
enhancement bonus). The weapon also becomes good, which means it can
bypass the damage reduction of certain creatures. (This effect
overrides and suppresses any other alignment the weapon might have.)
Individual arrows or bolts can be transmuted, but affected projectile
weapons (such as bows) don&rsquo;t confer the benefit to the
projectiles they
shoot.</p>
    <p>In addition, all critical hit rolls against evil foes are
automatically successful, so every threat is a critical hit. This last
effect does not apply to any weapon that already has a magical effect
related to critical hits, such as a keen weapon or a vorpal sword.</p>
    <h6><a id="blight">Blight</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Drd 4, Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude half; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell withers a single plant of any size. An affected
plant creature takes 1d6 points of damage per level (maximum 15d6) and
may attempt a Fortitude saving throw for half damage. A plant that
isn&rsquo;t a creature doesn&rsquo;t receive a save and immediately
withers and
dies.</p>
    <p>This spell has no effect on the soil or surrounding plant life.</p>
    <h6><a id="blindness-deafness">Blindness/Deafness</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Clr 3, Sor/Wiz 2</span>
    <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Target</b>: One living
creature</span> <span className="stat-block"><b>Duration</b>: Permanent (D)</span>
    <span className="stat-block"><b>Saving Throw</b>: Fortitude negates</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You call upon the powers of unlife to render the subject <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinded</a> or <a href="abilitiesAndConditions.html#deafened" style={{
        "color": "rgb(87, 158, 182)"
      }}>deafened</a>, as you choose.</p>
    <h6><a id="blink">Blink</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 round/level (D)</span>
    <p>You &ldquo;blink&rdquo; back and forth between the Material
Plane and the
Ethereal Plane. You look as though you&rsquo;re winking in and out of
reality
very quickly and at random.</p>
    <p>Blinking has several effects, as follows.</p>
    <p>Physical attacks against you have a 50% miss chance, and the <a href="featsAll.html#blind-fight" style={{
        "color": "rgb(87, 158, 182)"
      }}>Blind-Fight</a>
feat doesn&rsquo;t help
opponents, since you&rsquo;re ethereal and not
merely invisible. If the attack is capable of striking ethereal
creatures, the miss chance is only 20% (for concealment).</p>
    <p>If the attacker can see <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> creatures, the miss
chance
is also only 20%. (For an attacker who can both see and strike ethereal
creatures, there is no miss chance.) Likewise, your own attacks have a
20% miss chance, since you sometimes go ethereal just as you are about
to strike.</p>
    <p>Any individually targeted spell has a 50% chance to fail
against you while you&rsquo;re blinking unless your attacker can target
invisible, ethereal creatures. Your own spells have a 20% chance to
activate just as you go ethereal, in which case they typically do not
affect the Material Plane.</p>
    <p>While blinking, you take only half damage from area attacks
(but full damage from those that extend onto the Ethereal Plane). You
strike as an invisible creature (with a +2 bonus on attack rolls),
denying your target any Dexterity bonus to AC.</p>
    <p>You take only half damage from falling, since you fall only
while you are material.</p>
    <p>While blinking, you can step through (but not see through)
solid objects. For each 5 feet of solid material you walk through,
there is a 50% chance that you become material. If this occurs, you are
shunted off to the nearest open space and take 1d6 points of damage per
5 feet so traveled. You can move at only three-quarters speed (because
movement on the Ethereal Plane is at half speed, and you spend about
half your time there and half your time material.)</p>
    <p>Since you spend about half your time on the Ethereal Plane,
you can see and even attack ethereal creatures. You interact with
ethereal creatures roughly the same way you interact with material ones.</p>
    <p>An ethereal creature is invisible, <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a>, and capable of
moving in any direction, even up or down. As an incorporeal creature,
you can move through solid objects, including living creatures.</p>
    <p>An ethereal creature can see and hear the Material Plane, but
everything looks gray and insubstantial. Sight and hearing on the
Material Plane are limited to 60 feet.</p>
    <p>Force effects and abjurations affect you normally. Their
effects extend onto the Ethereal Plane from the Material Plane, but not
vice versa. An ethereal creature can&rsquo;t attack material creatures,
and
spells you cast while ethereal affect only other ethereal things.
Certain material creatures or objects have attacks or effects that work
on the Ethereal Plane. Treat other ethereal creatures and objects as
material.</p>
    <h6><a id="blur">Blur</a></h6>
    <p className="initial"><i>Illusion (Glamer)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2,Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Creature touched</span> <span className="stat-block"><b>Duration</b>: 1
min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>The subject&rsquo;s outline appears blurred, shifting and
wavering.
This distortion grants the subject concealment (20% miss chance).</p>
    <p>A see invisibility spell does not counteract the blur effect,
but a true seeing spell does.</p>
    <p>Opponents that cannot see the subject ignore the spell&rsquo;s
effect (though fighting an unseen opponent carries penalties of its
own).</p>
    <h6><a id="break-enchantment">Break Enchantment</a></h6>
    <p className="initial"><i>Abjuration</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Clr 5, Luck 5, Pal
4, Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S</span>
    <span className="stat-block"><b>Casting Time</b>: 1 minute</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: Up to one
creature per
level, all within 30 ft. of each other</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: See
text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell frees victims from enchantments, transmutations,
and curses. Break enchantment can reverse even an instantaneous effect.
For each such effect, you make a caster level check (1d20 + caster
level, maximum +15) against a DC of 11 + caster level of the effect.
Success means that the creature is free of the spell, curse, or effect.
For a cursed magic item, the DC is 25.</p>
    <p>If the spell is one that cannot be dispelled by dispel magic,
break enchantment works only if that spell is 5th level or lower. </p>
    <p>If the effect comes from some permanent magic item break
enchantment does not remove the curse from the item, but it does frees
the victim from the item&rsquo;s effects. </p>
    <h6><a id="bulls-strength">Bull&rsquo;s Strength</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2, Drd 2, Pal 2,
Sor/Wiz 2, Strength 2</span> <span className="stat-block"><b>Components</b>:
V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1
standard action</span> <span className="stat-block"><b>Range</b>: Touch</span>
    <span className="stat-block"><b>Target</b>: Creature touched</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes (harmless)</span>
    <p>The subject becomes stronger. The spell grants a +4
enhancement bonus to Strength, adding the usual benefits to melee
attack rolls, melee damage rolls, and other uses of the Strength
modifier.</p>
    <p><i>Arcane Material Component</i>: A few hairs, or a pinch of
dung, from a bull.</p>
    <h6><a id="mass-bulls-strength">Bull&rsquo;s Strength, Mass</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6, Drd 6, Sor/Wiz 6</span>
    <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One
creature/level, no
two of which can be more than 30 ft. apart</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#bulls-strength">bull&rsquo;s strength</a>, except that
it
affects multiple creatures.</p>
    <h6><a id="burning-hands">Burning Hands</a></h6>
    <p className="initial"><i>Evocation [Fire]</i></p>
    <span className="stat-block"><b>Level</b>: Fire 1, Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 15 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped burst</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Reflex half</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>A cone of searing flame shoots from your fingertips. Any
creature in the area of the flames takes 1d4 points of fire damage per
caster level (maximum 5d4). Flammable materials burn if the flames
touch them. A character can extinguish burning items as a full-round
action.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      